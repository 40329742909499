/* About Us text styles */
.about-us-text {
    font-size: 1rem;
    line-height: 1.6;
    margin: 3vw;
    color: #000000;
}

/* Media Query for Portrait Orientation */
@media screen and (orientation: portrait) {
    /* Adjusted styles for portrait orientation */
    .about-us-text {
        padding:6vw;
        margin-top:10vw;
        margin-bottom: -5vw;
    }
}
.about-container {
    max-width: 90vw;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.about-container h2 {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 20px;
}

.about-container h3 {
    color: #555;
    font-size: 1.8rem;
    margin-top: 40px;
    margin-bottom: 20px;
}

.about-container p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 30px;
}

/* Mobile View Styles (Portrait Orientation) */
@media screen and (orientation: portrait) {
    .about-container {
        max-width: 90vw;
        margin: 20px auto;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .about-container h2 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        padding-bottom: 15px;
    }

    .about-container h3 {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .about-container p {
        font-size: 0.9rem;
        margin-bottom: 20px;
    }
}
body {
    font-family: 'Urbanist', sans-serif;
}

.navbar {
    display: flex;
    align-items: center;
    background-color: #FFD200;
    padding: 0 5vw;
    justify-content: space-between;
}

.navbar a {
    text-decoration: none;
    color: #333;
}

.navbar-logo {
    margin-left:0.5vw;
}

.logo-img {
    height: 5vw;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #FFD200;
    color: #333;
    border: none;
    cursor: pointer;
    padding: 10px 16px;
    transition: 0.3s;
}

.dropbtn:hover {
    background-color: #FFD200;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5vw;
    font-size: 1.1em;
}

.menu-container > *:not(:last-child) {
    margin-right: 5vw;
}

@media screen and (orientation: portrait) {
    
    .navbar-logo-m .logo-img-m {
        height: 12vw;
        margin-left:1vw;
    }
    .menu-container {
        font-size: 0.8em;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-right:3vw;
    }
}
/* Footer container styles */
.footer-container {
    background: #2c3e50;
    color: #ecf0f1;
    padding: 2em 0; /* Adjusted padding for mobile in em units */
}

/* Inner container styles */
.inner-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

/* Contact information styles */
.contact-info {
    margin-bottom: 1em; /* Adjusted margin in em units */
}

.contact-info h3 {
    margin-bottom: 0.5em; /* Adjusted margin in em units */
}

/* Follow Us styles */
.follow-us {
    margin-bottom: 1em; /* Adjusted margin in em units */
}

.follow-us h3 {
    margin-bottom: 0.5em; /* Adjusted margin in em units */
}

.follow-us a {
    margin-right: 1.5em; /* Adjusted margin in em units */
    color: #ecf0f1;
}

/* Centered text styles */
.centered-text {
    margin-top: 2em; /* Adjusted margin in em units */
    text-align: center;
}

/* Media Query for Mobile */
@media screen and (orientation: portrait) {
    /* Example styles for portrait (mobile) view */
    .footer-container {
        padding: 1em 0; /* Adjusted padding for mobile in em units */
    }

    .contact-info,
    .follow-us {
        width: 100%; /* Full width for mobile */
        text-align: center; /* Center text for mobile */
    }

    .follow-us a {
        margin-right: 0; /* No margin on the right for mobile */
        margin-bottom: 1em; /* Adjusted margin in em units */
    }

    .contact-info,
    .follow-us,
    .centered-text {
        padding: 0 1em; /* Adjusted padding to the sides for better spacing in em units */
        margin: 1em;
    }
    
    /* Additional spacing between social icons in mobile view */
    .follow-us a + a {
        margin-left: 15px; /* Adjust the spacing between social icons */
    }
}

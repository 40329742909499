/* Service Highlights container styles */
.service-highlights-container {
    max-width: 90vw;
    margin: 2.5em auto;
    padding: 1em;
}

/* Service Highlights title styles */
.service-highlights-title {
    color: #2c3e50;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1em;
}

/* Service Highlights list styles */
.service-highlights-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Service Highlight item styles */
.service-highlight-item {
    width: calc(30% - 1em); /* Adjusted width using calc() to ensure spacing between items */
    padding: 1em;
    box-sizing: border-box;
    margin-bottom: 1em;
}

/* Service Highlight heading styles */
.service-highlight-heading {
    font-size: 1.5rem;
    margin-bottom: 1em;
    color: #34495e;
}

/* Service Highlight description styles */
.service-highlight-description {
    font-size: 1rem;
    color: #7f8c8d;
}

/* Media Query for Portrait Orientation */
@media screen and (orientation: portrait) {
    /* Adjusted styles for portrait orientation */
    .service-highlights-container {
        padding: 1em;
    }

    .service-highlights-title {
        font-size: 2rem;
    }

    .service-highlight-item {
        width: 100%; /* Full width for mobile view */
        margin-bottom: 0.1em;
    }

    .service-highlight-heading {
        font-size: 1.2rem;
        margin-bottom: 0.8em;
    }
}
/* FAQ container styles */
.faq-container {
    max-width: 90vw;
    margin: 50px auto;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 5px;
}

/* FAQ title styles */
.faq-title {
    color: #2c3e50;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 40px;
}

/* FAQ list styles */
.faq-list {
    display: flex;
    flex-direction: column;
}

/* FAQ item styles */
.faq-item {
    margin-bottom: 20px;
}

/* FAQ question styles */
.faq-question {
    cursor: pointer;
    background: #fff;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* FAQ answer styles */
.faq-answer {
    margin-top: 1em;
    padding: 1em;
    background: #eaeaea;
    border-radius: 5px;
}

/* Media Query for Mobile */
@media screen and (orientation: portrait) {
    /* Adjusted styles for mobile view */
    .faq-container {
        padding: 1em;
    }

    .faq-title {
        font-size: 1.6rem;
    }

    .faq-question {
        padding: 0.8em;
    }

    .faq-answer {
        margin-top: 0.8em;
    }
}